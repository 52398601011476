import React, { useState, useEffect } from "react";
import { addDays } from "date-fns";
import { Line } from "react-chartjs-2";
import CustomDatePicker from "./datepicker";
import { queryReport } from "./queryReport";
import { ChartTitle, ReportWrapper, PieChartWrapper, colors, ChartWrapper, Subtitle, DatepickerRow } from "./styles";
import { Button, Box, Center, Table, Tbody, Tr,Th, Thead, Td } from "@chakra-ui/react";



const VisitPerDayGA = (props) => {
  const INITIAL_STATE = {
    labels: [],
    values: [],
    colors: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [startDate, setStartDate] = useState(addDays(new Date(), -10));
  const [endDate, setEndDate] = useState(new Date());
  const [totalUsers, setTotalUsers] = useState(0);
  const [tableVisits, setTableVisits] = useState([]);


  const displayResults = (response) => {
    const queryResult = response.result.reports[0].data.rows;
    const total = response.result.reports[0].data.totals[0].values[0];
    setTotalUsers(total);
    let labels = [];
    let values = [];
    let bgColors = [];
    queryResult.forEach((row, id) => {
      labels.push(row.dimensions[0]);
      values.push(row.metrics[0].values[0]);
      bgColors.push(colors[id + 4]);
    });
    setReportData({
      ...reportData,
      labels,
      values,
      colors: bgColors,
    });
  };
  

  function dateBeautify(date){ 
      let arr = [];

    for (let i = 0; i < reportData.labels.length; i++) {

        let dash = "-";
        
        let tempDate = reportData.labels[i];
        let endString = tempDate.length;

        let year = tempDate.substring(0, 4);
        let month = tempDate.substring(4, 6);
        let day = tempDate.substring(6, endString);

        let realDate = year.concat(dash,month,dash,day);


      arr.push(realDate);
    }
    return arr;
  }


  const data = {
    labels: dateBeautify(reportData.labels),
    datasets: [
      {
        label: `Visits per day`,
        fill: false,
        lineTension: 0.3,
        borderColor: "#D32424",
        // backgroundColor: "#D32424",
        pointBorderWidth: 3,
        pointHoverBackgroundColor: "#D32424",
        pointHoverBorderColor: "#D32424",
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        borderWidth: 1, // Specify bar border width
        pointHitRadius: 10,
        data: reportData.values,
      },
    ],
  };

  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            fontColor: "white",
            fontSize: 15,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 7,
            maxRotation: 0,
            minRotation: 0,
            fontColor: "white",
            fontSize: 15,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontColor: "white",
        fontSize: 18,
      },
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  useEffect(() => {
    const request = {
      viewID: props.viewID,
      startDate,
      endDate,
      metrics: "ga:pageviews",
      dimensions: ["ga:date"],
    };
    setTimeout(
      () =>
        queryReport(request)
          .then((resp) => displayResults(resp))
          .catch((error) => console.error(error)),
      1500
    );
  }, [startDate, endDate]);

  useEffect(() => {
    let arr = [];

    for (let i = 0; i < reportData.labels.length; i++) {

        let dash = "-";
        
        let tempDate = reportData.labels[i];
        let endString = tempDate.length;

        let year = tempDate.substring(0, 4);
        let month = tempDate.substring(4, 6);
        let day = tempDate.substring(6, endString);

        let realDate = year.concat(dash,month,dash,day);
        
      let obj = {
        labels: realDate,
        values: reportData.values[i],
      };

      arr.push(obj);
    }

    setTableVisits(arr);
  }, [reportData]);


  return (
    <Box py="80px" borderBottom="1px solid" mb={5}>
      <ReportWrapper>
        <ChartTitle>{`Visits per day (Table)`}</ChartTitle>
        <Subtitle d="flex" flexDir="row">
          {/* <Button
            fontSize="21px"
            p={5}
            m={5}
            bg="#D32424">{`Average - ${average}`}</Button> */}

          <Button m={5} fontSize="21px" p={5} bg="#D32424">
            {`Total - ${totalUsers}`}
          </Button>
        </Subtitle>
        <DatepickerRow>
          <CustomDatePicker
            placeholder={"Start date"}
            date={startDate}
            handleDateChange={(date) => setStartDate(date)}
          />
          <CustomDatePicker
            placeholder={"End date"}
            date={endDate}
            handleDateChange={(date) => setEndDate(date)}
          />
        </DatepickerRow>
        {tableVisits.length && (
          <Center>
            <Center w="800px">
              <Box className="table-wrapper">
                <Table className="pageviewReport">
                  <Thead>
                    <Tr>
                      <Th
                        position="sticky"
                        top="0"
                        textAlign="center"
                        color="white">
                        Date
                      </Th>
                      <Th
                        position="sticky"
                        top="0"
                        className="views"
                        color="white">
                        Visits
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tableVisits.map((row, id) => (
                      <Tr key={id}>
                        <Td className="views">{row.labels}</Td>
                        <Td className="views">{row.values}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Center>
          </Center>
        )}

        <Box pt="80px">
          <ChartTitle>{`Visits per day  (Graph)`}</ChartTitle>

          {reportData && (
            <ChartWrapper>
              <Line data={data} options={options} width={100} height={250} />
            </ChartWrapper>
          )}
        </Box>
      </ReportWrapper>
    </Box>
  );
};

export default VisitPerDayGA;
