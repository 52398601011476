import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from "axios";
import { renderButton, checkSignedIn } from "../utils";
import logo from "assets/ijm_logo2.png";

export default function Login({ setLoggedIn }) {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  let history = useHistory();
  const [isSignedIn, setIsSignedIn] = useState(false);

  const updateSignin = (signedIn) => {
    //(3)
    setIsSignedIn(signedIn);
    renderButton();
  };

  const init = () => {
    //(2)
    checkSignedIn()
      .then((signedIn) => {
        updateSignin(signedIn);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    window.gapi.load("auth2", init); //(1)
  });

  function handleRefresh() {
    let loginStatus = document.getElementById("signin-button").innerText;

    if (loginStatus === "Signed in with Google") {
      setLoggedIn(true);
      history.push("/");
    }
  }

  useEffect(() => {
    const interval_id = setInterval(handleRefresh, 1000);
    return () => {
      // Stop the interval when the component unmounts.
      // Otherwise it will keeep going and you will get an error.
      clearInterval(interval_id);
    };
  }, []); // empty array of dependencies will cause it to run on mount and unmount only

  // function handleSubmit(e) {
  //     e.preventDefault();
  //     if (user && password && !user.includes(" ") && !password.includes(" ")) {
  //         Axios.post(`${linkUsed()}/admin-login`, {
  //             user: user,
  //             password: password
  //         }).then((res) => {
  //             console.log(res)
  //             setMessage(res.data.message)
  //             if (!res.data.error) {
  //                 setLoggedIn(true)
  //                 history.push('/')
  //             }
  //         })

  //     } else {return console.log("Invalid Login")}

  // }

  return (
    <div className="form-container">
      <img
        style={{ marginBottom: "50px", marginTop: "60px" }}
        className="logo"
        alt="logo"
        src={logo}
        width="300px"
        height="300px"
      />
      <h2 style={{ marginBottom: "70px", fontSize: "1vw" }}>Admin Log In</h2>

      <form>
        <div id="signin-button"></div>
      </form>

      {/* <form onSubmit={e => handleSubmit(e)}>
            <div className="inputContainer">
                <label className="inputTitle"><p>Username</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="text" value={user} onChange={e => setUser(e.target.value)}/>
            </div>
            <div className="inputContainer">
                <label className="inputTitle"><p>Password</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="password" value={password} onChange={e => setPassword(e.target.value)}/>
            </div>
            
            <p>{message}</p>

            <button className="submit">Login</button>
            </form> */}
    </div>
  );
}
