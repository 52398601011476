import { useState, useEffect } from "react";
import "./App.css";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import Home from "routes/Home";
import Dashboard from "routes/Dashboard";
import Login from "routes/Login";
import Analytics from "routes/Analytics";
import Booking from "routes/Booking";
import Announcement from "routes/Announcement";
import Header from "Components/header";
import AgentsVisitsReport from "Dashboard/agentsVisitsReport";
import Registered from "routes/Registered";
import AnnouncementRich from "routes/AnnouncementRich";
import { HashLink } from "react-router-hash-link";
import TempAnnounceSuccess from "routes/TempAnnounceSuccess"

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [approving, setApproving] = useState(false);
  const [disInfo, setDisInfo] = useState(false);
  const [info, setInfo] = useState({});
  const [pageViews, setPageViews] = useState([]);

  let history = useHistory();

  const logOutAuth = () => {
    let auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      console.log("User Signed Out");
    });
  };

  useEffect(() => {
    if (!loggedIn) {
      history.push("/login");
    }

    if (loggedIn) {
      history.push("/dashboard");
    }
  }, []);

  function Logout() {
    setLoggedIn(false);
    logOutAuth();
    history.push("/");
  }

  return (
    <div>
      <Header Logout={Logout} loggedIn={loggedIn} />

      <Switch>
        <Route exact path="/">
          <Home loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
        </Route>

        <Route path="/dashboard">
          <Dashboard
            loggedIn={loggedIn}
            info={info}
            setInfo={setInfo}
            setLoggedIn={setLoggedIn}
            disInfo={disInfo}
            setDisInfo={setDisInfo}
            approving={approving}
            setApproving={setApproving}
          />
        </Route>

        <Route path="/login">
          <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
        </Route>

        <Route path="/analytics">
          <Analytics pageViews={pageViews} setPageViews={setPageViews} />
        </Route>
        <Route path="/booking">
          <Booking loggedIn={loggedIn} />
        </Route>

        <Route path="/announcement">
          <Announcement />
          {/* <AnnouncementRich /> */}
        </Route>
        <Route path="/agents-analytics">
          <AgentsVisitsReport />
        </Route>
        <Route path="/registered">
          <Registered />
        </Route>
        <Route path="/success">
          <TempAnnounceSuccess />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
