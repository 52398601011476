import Axios from "axios";
import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Stack,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Box,
  Text,
} from "@chakra-ui/react";
import ModalBook from "Components/ModalBook";
import ModalReject from "Components/ModalReject";
import ModalRejectInfo from "Components/ModalRejectInfo";
import ModalInfo from "Components/ModalInfo";
import FileSaver from "file-saver";
import { linkUsed } from "utils/link";

export default function Booking({ loggedIn }) {
  const [list, setList] = useState([]);
  const [accept, setIsAccept] = useState(false);

  let BOOKING_ENDPOINT = `${linkUsed()}`;

  useEffect(() => {
    console.log(accept);
  }, [accept]);

  useEffect(() => {
    if (loggedIn) {
      Axios.get(`${BOOKING_ENDPOINT}/book-list`)
        .then((res) => {
          setList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  async function getBookingForm(e, booking_number) {
    e.preventDefault();

    await Axios.post(
      `${BOOKING_ENDPOINT}/get-booking`,
      {
        booking_number,
      },
      { responseType: "arraybuffer" }
    ).then((res) => {
      FileSaver.saveAs(
        new Blob([res.data], { type: "application/pdf" }),
        `${booking_number}.pdf`
      );
    });
  }

  async function getPaymentSlip(e, booking_number, fileType) {
    e.preventDefault();

    function getMIMEType(fileType) {
      switch (fileType) {
        case "jpg":
          return "image/jpg";

        case "jpeg":
          return "image/jpg";

        case "png":
          return "image/png";

        case "pdf":
          return "application/pdf";
      }
    }

    console.log(getMIMEType(fileType));

    await Axios.post(
      `${BOOKING_ENDPOINT}/get-slip`,
      {
        booking_number,
        fileType,
      },
      { responseType: "arraybuffer" }
    ).then((res) => {
      FileSaver.saveAs(
        new Blob([res.data], { type: getMIMEType(fileType) }),
        `${booking_number}.${fileType}`
      );
    });
  }

  let client_rows = list.map((user, key) => (
    <Tr key={key}>
      <Td className="key">{key + 1}</Td>
      <Td w="180px">{user.name}</Td>
      <Td className="phoneTd">
        <Button onClick={(e) => getBookingForm(e, user.booking_number)}>
          <Text>Booking Form</Text>
        </Button>
      </Td>
      <Td className="phoneTd">{user.status ? "Paid" : "Unpaid"}</Td>
      <Td className="phoneTd">
        {user.status ? (
          <Button
            onClick={(e) =>
              getPaymentSlip(e, user.booking_number, user.fileType)
            }>
            <Text>{user.booking_number}</Text>
          </Button>
        ) : (
          user.booking_number
        )}
      </Td>
      <Td className="phoneTd">
        {user.agent_name} <br /> {user.agent_email}
      </Td>
      <Td w="50px">
        {user.status ? (
          <>
            {user.verified ? (
              <ModalInfo user={user} />
            ) : (
              <>
                {user.rejected ? (
                  <ModalRejectInfo user={user} />
                ) : (
                  <>
                    <Stack direction="row" spacing={4}>
                      <ModalBook user={user} setList={setList} />
                      <ModalReject user={user} setList={setList} />
                    </Stack>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <ModalInfo user={user} />
        )}
      </Td>
    </Tr>
  ));

  return (
    <Box mx="40">
      <Table>
        <Thead>
          <Tr className="tr_link">
            <Th className="no">No.</Th>
            <Th>
              <Text>Name</Text>
            </Th>

            <Th>
              <p>Booking Form</p>
            </Th>
            <Th>
              <p>Status</p>
            </Th>
            <Th>
              <p>Booking Number</p>
            </Th>

            <Th textAlign="center">Agent</Th>
            <Th w="50px" textAlign="center">
              Action
            </Th>
          </Tr>
          <br />
        </Thead>
        <Tfoot>
          <Tr>
            <Td colSpan="6"></Td>
          </Tr>
        </Tfoot>
        <Tbody>
          {list.length === 0 ? (
            <Td colSpan="9"> Once there's a booking, it will show up here. </Td>
          ) : (
            client_rows
          )}
        </Tbody>
      </Table>
    </Box>
  );
}
