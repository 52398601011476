import Axios from "axios";
import { linkUsed } from "utils/link";

export function handleClick(e, user, setRegistered, registered) {
  e.preventDefault();

  async function Post() {
    console.log("calling api")
    await Axios.post(`${linkUsed()}/approve`, {
      // await Axios.post("data.json", {
      email: user.email,
      password: user.password,
      full_name: user.full_name,
      agency: user.agency,
      phone: user.phone,
      status: 1,
    }).then((res) => {

      let updatedArr = [...registered];

      for (let i = 0; i < updatedArr.length; i++) {
        if (updatedArr[i].id === res.data.user.id) {
          updatedArr[i] = {
            id: updatedArr[i].id,
            email: updatedArr[i].email,
            full_name: updatedArr[i].full_name,
            agency: updatedArr[i].agency,
            phone: updatedArr[i].phone,
            status: 1,
            password: res.data.user.password,
            linkHtm: res.data.user.agencyHtm,
            date_registered: res.data.user.date,
            pageViews: 0,
          };
          break;
        }
      }

      setRegistered(updatedArr);
    });
  }

  Post();
}
