import Axios from "axios";
import { useState, useEffect } from "react";
import ArrayToExcelMain from "Components/ArrayToExcelMain";
import ModalAgentDelete from "Components/ModalAgentDelete";
import AgentsVisitsReport from "Dashboard/agentsVisitsReport";
import FirstIcon from "assets/first.png";
import LastIcon from "assets/last.png";
import PrevIcon from "assets/prev.png";
import NextIcon from "assets/next.png";
import {
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Center,
  Link,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
  DeleteIcon,
  EmailIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import ModalChakra from "../Components/ModalChakra";
import Pagination from "react-js-pagination";
import { getInfo, handleClick } from "../DashboardComps/rest";
import { linkUsed } from "../utils/link";


export default function Dashboard({ loggedIn, approving, setApproving }) {
  const [pendingAgents, setPendingAgents] = useState([]);
  const [registered, setRegistered] = useState([]);

  // Filtering
  const [name, setName] = useState(true);
  const [byAgency, setByAgency] = useState(false);
  const [email, setEmail] = useState(false);
  const [filterName, setFilterName] = useState("Name");
  const [search, setSearch] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);

  const [byAll, setByAll] = useState(true);
  const [byApproved, setByApproved] = useState(false);
  const [byPending, setByPending] = useState(false);
  const [byDescending, setByDescending] = useState(false);
  const [byAscending, setByAscending] = useState(false);
  const [status, setStatus] = useState("All");
  const [agentsPageViews, setAgentsPageViews] = useState(null);

  useEffect(() => {
    if (loggedIn) {
      fetchData();
    }
  }, [loggedIn]);

  const fetchData = async () => {
    await Axios.get(`${linkUsed()}/all-agents`)
    // await Axios.get("tempAgentData.json")
      .then((res) => {
        let agents = res.data;
        setRegistered([...registered, ...agents]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  async function Reject(e, user) {
    e.preventDefault();
    console.log(user);

    await Axios.post(`${linkUsed()}/reject`, {
      // await Axios.post("data.json", {
      email: user.email,
      password: user.password,
      full_name: user.full_name,
      agency: user.agency,
      phone: user.phone,
      status: user.status,
    }).then((res) => {
      let updatedArr = [...registered];

      for (let i = 0; i < updatedArr.length; i++) {
        if (updatedArr[i].email === user.email) {
          updatedArr.splice(i, 1);
          break;
        }
      }

      setRegistered(updatedArr);
    });
  }

  useEffect(() => {
    fetchPageViews();
  }, [agentsPageViews]);

  const fetchPageViews = async () => {
    if (agentsPageViews && agentsPageViews.length > 0) {
      let agents = [];
      registered.forEach((row) => {
        if (checkViews(row) === 0) {
          row = { ...row, pageViews: checkViews(row) };
        } else if (checkViews(row)) {
          row = { ...row, pageViews: parseInt(checkViews(row)) };
        }
        agents.push(row);
      });

      function checkViews(agent) {
        let pageViews = 0;

        for (let i = 0; i < agentsPageViews.length; i++) {
          if (agentsPageViews[i].dimensions[0] === agent.linkHtm) {
            pageViews = agentsPageViews[i].metrics[0].values[0];
            break;
          }
        }
        return pageViews;
      }

      setRegistered(agents);
    }
  };

  function Resend(e, user) {
    e.preventDefault();
    setApproving(true);
    async function Post() {
      await Axios.post(`${linkUsed()}/resend`, {
        // await Axios.post("data.json", {
        email: user.email,
        password: user.password,
        full_name: user.full_name,
        agency: user.agency,
        phone: user.phone,
        status: user.status,
      }).then((res) => {
        setApproving(res.data.message);
      });
    }
    Post();
  }

  function Approved({ user }) {
    return (
      <Box
        display="flex"
        flexDir="row"
        alignItems="center"
        justifyContent="space-around">
        <ModalAgentDelete
          user={user}
          setRegistered={setRegistered}
          registered={registered}
        />
        <Button
          w="10vw"
          fontSize="sm"
          mr={3}
          onClick={(e) => Resend(e, user)}
          leftIcon={<EmailIcon />}
          >       
          Resend
        </Button>
      </Box>
    );
  }

  useEffect(() => {
    if (byAll) {
      setFilteredDatas(
        registered.filter((data) => {
          let filtered;
          if (name) {
            filtered = data.full_name
              .toLowerCase()
              .includes(search.toLowerCase());
          } else if (byAgency) {
            filtered = data.agency.toLowerCase().includes(search.toLowerCase());
          } else if (email) {
            filtered = data.email.toLowerCase().includes(search.toLowerCase());
          }
          return filtered;
        })
      );
    } else if (byApproved) {
      setFilteredDatas(
        registered.filter((data) => {
          let filtered;
          if (data.status === 1) {
            if (name) {
              filtered = data.full_name
                .toLowerCase()
                .includes(search.toLowerCase());
            } else if (byAgency) {
              filtered = data.agency
                .toLowerCase()
                .includes(search.toLowerCase());
            } else if (email) {
              filtered = data.email
                .toLowerCase()
                .includes(search.toLowerCase());
            }
          }
          return filtered;
        })
      );
    } else if (byPending) {
      setFilteredDatas(
        registered.filter((data) => {
          let filtered;
          if (data.status === 0) {
            if (name) {
              filtered = data.full_name
                .toLowerCase()
                .includes(search.toLowerCase());
            } else if (byAgency) {
              filtered = data.agency
                .toLowerCase()
                .includes(search.toLowerCase());
            } else if (email) {
              filtered = data.email
                .toLowerCase()
                .includes(search.toLowerCase());
            }
          }
          return filtered;
        })
      );
    }
  }, [
    search,
    registered,
    byPending,
    byAll,
    byApproved,
    byDescending,
    byAscending,
    pendingAgents,
    byAgency,
    email,
    name,
  ]);

  function SortUp() {
    setByAscending(true);
    setByDescending(false);

    return setFilteredDatas(
      registered.sort((a, b) => {
        return a.pageViews - b.pageViews;
      })
    );
  }

  function SortDown() {
    setByDescending(true);
    setByAscending(false);
    return setFilteredDatas(
      registered.sort((a, b) => {
        return b.pageViews - a.pageViews;
      })
    );
  }

  function getLink(linkHtm) {
    if (!linkHtm) {
      return `-`;
    }
    return (
      <>
        <Link
          overflow="hidden"
          textOverflow="ellipsis"
          fontSize="0.8vw"
          // href={`https://vgallery.my3dvision.com/vgklcc/${linkHtm}.htm`}
          href={`https://vr.thelightcity.com.my/${linkHtm}.htm`}
          target="_blank">
          {linkHtm}
        </Link>
        <ExternalLinkIcon mx="2px" />
      </>
    );
  }

  // Pagination
  const todosPerPage = 15;
  const [activePage, setCurrentPage] = useState(1);

  // Logic for displaying current todos
  const indexOfLastTodo = activePage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentFilteredDatas = filteredDatas.slice(
    indexOfFirstTodo,
    indexOfLastTodo
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let agent_rows = currentFilteredDatas.map((user, key) => {
    return (
      <Tr key={key}>
        <Td className="key">{key + 1}.</Td>
        <Td p={0}>
          <Box
            d="flex"
            fontSize="0.8vw"
            alignItems="center"
            justifyContent="space-between">
            {user.full_name} <ModalChakra user={user} />
          </Box>
        </Td>
        <Td p={0} textAlign="center">
          <Button w="10vw" fontSize="sm" m={0}>
            {getLink(user.linkHtm)}
          </Button>
        </Td>
        <Td p={0} textAlign="center">
          {user.pageViews}
        </Td>

        <Td className="status">
          {user.status ? (
            <Text
              as="p"
              fontSize="0.8rem"
              bg="green.600"
              alignContent="center"
              textAlign="center"
              w="10vw"
              borderRadius="md"
              p={2}
              color="white">
              Approved
            </Text>
          ) : (
            <Text as="p" textAlign="center" className="pending">
              Pending
            </Text>
          )}
        </Td>
        <Td>
          <Center justifyContent="space-around">
            {user.status ? (
              <Approved user={user} />
            ) : (
              <Box
                display="flex"
                flexDir="row"
                alignItems="center"
                justifyContent="space-around">
                <Button
                  bg="green.600"
                  leftIcon={<CheckIcon />}
                  w="10vw"
                  fontSize="sm"
                  mr={3}
                  onClick={(e) =>
                    handleClick(e, user, setRegistered, registered)
                  }
                  >
                  Approve
                </Button>
                <Button
                  w="10vw"
                  fontSize="sm"
                  mr={3}
                  className="reject"
                  onClick={(e) => Reject(e, user)}
                  >
                  Reject
                </Button>
              </Box>
            )}
          </Center>
        </Td>
      </Tr>
    );
  });

  return (
    <Center>
      <Box>
        <AgentsVisitsReport setAgentsPageViews={setAgentsPageViews} />
        <Table className="table">
          <Thead>
            <Tr>
              <Th className="no">No.</Th>
              <Th p={0}>
                <p>Agent</p>
              </Th>
              <Th p={0} textAlign="center">
                <Text p={0}>Link</Text>
              </Th>

              <Th textAlign="center">
                <Box
                  justifyContent="center"
                  d="flex"
                  flexDir="row"
                  alignItems="center">
                  <Text>Total pageviews</Text>
                  <Menu isLazy p={0} m={0}>
                    <MenuButton p={0} ml={2} as={Button}>
                      {byAscending ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={SortUp}>Sort Ascending</MenuItem>
                      <MenuItem onClick={SortDown}>Sort Descending</MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Th>

              <Th px={6}>
                <Box
                  w="-moz-fit-content"
                  d="flex"
                  flexDir="row"
                  justifyContent="space-around"
                  alignItems="center">
                  <Text textAlign="center">Status : </Text>
                  <Menu isLazy>
                    <MenuButton>
                      <Button
                        ml="0.5vw"
                        w="7vw"
                        fontSize="0.8vw"
                        rightIcon={<ChevronDownIcon />}>
                        {status}
                      </Button>
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={(e) => {
                          setByAll(true);
                          setByApproved(false);
                          setByPending(false);
                          setStatus("All");
                        }}>
                        All
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          setByAll(false);
                          setByApproved(true);
                          setByPending(false);
                          setStatus("Approved");
                        }}>
                        Approved
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          setByAll(false);
                          setByApproved(false);
                          setByPending(true);
                          setStatus("Pending");
                        }}
                        >
                        Pending
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Th>

              <Th d="flex" flexDir="row">
                <Center d="flex" flexDir="row">
                  <Input
                    type="text"
                    placeholder={`Search By ${filterName}`}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Menu>
                    <MenuButton isLazy>
                      <Button>{filterName}</Button>
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={(e) => {
                          setByAgency(false);
                          setName(true);
                          setEmail(false);
                          setFilterName("Name");
                        }}>
                        Name
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          setByAgency(true);
                          setName(false);
                          setEmail(false);
                          setFilterName("Agency");
                        }}>
                        Agency
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          setByAgency(false);
                          setName(false);
                          setEmail(true);
                          setFilterName("Email");
                        }}>
                        Email
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Center>
                <Center className="chakra-button">
                  {registered.length > 0 ? (
                    <ArrayToExcelMain registered={registered} />
                  ) : null}
                </Center>
              </Th>
            </Tr>
            <br />
          </Thead>
          <Tfoot>
            <Tr>
              <Td colSpan="6"></Td>
            </Tr>
          </Tfoot>

          <Tbody>
            {registered.length === 0 ? (
              <Td colSpan="9"> All caught up! </Td>
            ) : (
              agent_rows
            )}
          </Tbody>
        </Table>
        <Center p={0} m={0} className="pagination">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={15}
            totalItemsCount={filteredDatas.length}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
            prevPageText={
              <img style={{ width: "30px" }} alt="first" src={PrevIcon} />
            }
            firstPageText={
              <img style={{ width: "30px" }} alt="prev" src={FirstIcon} />
            }
            lastPageText={
              <img style={{ width: "30px" }} alt="Next" src={LastIcon} />
            }
            nextPageText={
              <img style={{ width: "30px" }} alt="Last" src={NextIcon} />
            }
            activeClass="active-class"
          />
        </Center>
      </Box>
    </Center>
  );
}
